<template>
  <div class="d-inline-flex ">
    <div v-for="(x,i) in 3" :key="x" :style="width">
      <v-icon :size="iconSize" v-if="score > i-1"  color="red">mdi-fire</v-icon>
      <v-icon :size="iconSize" color="grey" v-else>mdi-fire</v-icon>
    </div>
  </div>
</template>
<script>

export default {
  name: "ScoreLead",
  mixins: [],
  props: {
    score: {
      type: Number,
      required: true,
    },
    iconSize:{type:String,default:"medium"}
  },
  computed: {
    width() {
      let value;
      switch (this.iconSize) {
        case 'x-small':
          value = '9px'
          break;
        case 'small':
          value = '11px'
          break;
        case 'medium':
          value = '12px'
          break;
        case 'large':
          value = '14px'
          break;
        case 'x-large':
          value = '16px'
          break;
      }
      return `width:${value};`
    }
  }
};

</script>