<template>
  <layout :drawer="false">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'lead' }">Lead</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">
      <div class="mr-4">
        <smart-btn type="button" block secondary @click.native="dialogs.booking.show = true">
          Rattacher un dossier
        </smart-btn>
      </div>
      <div class="mr-4" v-if="selectedQuotes.length>0">
        <smart-btn type="button" block secondary @click.native="sendQuotes">
          Envoyer au client
        </smart-btn>
      </div>
      <div class="mr-4" v-if="selectedQuotes.length>0">
        <smart-btn type="button" block action="delete" @click.native="dialogs.quotesRemove.show = true">
          Supprimer du Lead
        </smart-btn>
      </div>
    </template>
    <template slot="drawer"></template>
    <template slot="content">
      <v-row class="pa-1">
        <!-- ------------------- left bloc -------------------  -->
        <v-col cols="4" class="pr-1" dense>
          <v-row no-gutters>
            <v-col>
              <collapse-panel :fillHeight="false" :collapsable="false" :loading="loading" v-if="lead">
                <div slot="title">
                  <v-row align="center">
                    <v-col class="caption font-weight-medium">
                      {{ lead.label || "" }} <span class="caption grey--text lighten-1"
                    >#{{ lead.id }}</span
                    >
                    </v-col>

                    <v-col class="d-flex justify-end text-right align-center items-center">
                      <ScoreLead :score="lead.score" class="mr-3"/>
                      <v-chip small outlined label
                              :color="lead.status == 'open' ? 'green' : lead.status == 'close' ? 'red' : 'orange'"
                      >
                        {{ $t('lead.status.' + lead.status) }}
                      </v-chip>
                      <v-chip v-if="lead.status == 'close' && lead.failure" small outlined label color="red"
                              class="ml-1">{{ $t('lead.fail.' + lead.failure) }}
                      </v-chip>

                      <v-chip v-if="lead.converted" small outlined label color="green" class="ml-1">Convertis
                        {{ lead.bookingIdCloseOrigin ? "par #" + lead.bookingIdCloseOrigin : "" }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </div>

                <div slot="header">
                  <v-row dense>
                    <v-col cols="8">
                      <div class="d-flex justify-space-between rounded  align-center"
                           style="">
                        <div class="d-flex">
                          <div class="d-flex text-left align-center">
                            <span class="caption grey--text lighten-2">Budget: </span>
                            <span v-if="lead.budget" class="ml-1 font-weight-medium"> {{
                                lead.budget | currency("EUR", lead.budget)
                              }}</span>
                            <span v-else-if="quotations.length>0">{{ maxPrice | currency("EUR", maxPrice) }}</span>
                            <span v-else> - </span>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="4" class="align-self-end text-right">
                      <div class="d-flex rounded justify-end align-center text-right" style="">
                        <v-icon class="ml-3">mdi-account</v-icon>
                        <span :class="lead.user ? 'body-2' : 'caption'">{{
                            userName()
                          }}</span>
                      </div>
                    </v-col>
                    <v-col class="body-2">
                      <div class="">
                          <span class="caption">Créé le {{
                              _parseAndFormatDate(lead.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy hh:mm:ss")
                            }}</span>
                        <span v-if="lead.modifyDate"></span>
                      </div>
                      <div v-if="lead.message != null && lead.message != ''" class="d-flex flex-column rounded py-3"
                           style="">
                        <span class="grey--text lighten-2 caption">Commentaire:</span>
                        <div class="caption" v-html="htmlMessage"></div>
                        <!--                        <div class="caption">{{ htmlMessage }}</div>-->

                      </div>

                    </v-col>
                    <v-col cols="12">
                      <div v-if="dialogs.contacts.data.length > 0" class="caption grey--text">Autres contacts:
                        <div v-for="mail in dialogs.contacts.data" :key="mail">
                          - {{ mail }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex ">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                v-bind="attrs"
                                v-on="on"
                                label
                                flat
                                small
                                outlined
                                class="grey lighten-2 green--text text--lighten-2 mr-1"
                            >
                              <v-icon small class="mr-2">mdi-clipboard-text</v-icon>
                              {{ $t('lead.source.' + lead.source) }}
                            </v-chip>
                          </template>
                          Origine du lead
                        </v-tooltip>
                        <v-chip
                            label
                            flat
                            small
                            outlined
                            class="grey lighten-2 blue--text text--lighten-2  mr-1"
                            v-if="lead.destinations.length >0"
                        >

                          <v-icon small class="mr-2">mdi-tag</v-icon>
                          {{ lead.destinations.map(x => x.name).join(", ") }}
                        </v-chip>


                        <v-chip
                            label
                            flat
                            small
                            outlined
                            class="grey lighten-2 blue--text text--lighten-2 mr-1"
                        >
                          <v-icon small class="mr-2">mdi-phone</v-icon>
                          {{ lead.phonecalls }} appels
                        </v-chip>
                      </div>
                    </v-col>

                  </v-row>
                </div>

                <div slot="menu">
                  <v-list-item @click="dialogs.infos.show = true">
                    <v-list-item-title>
                      <v-icon small>edit</v-icon>
                      <span>Éditer le lead</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="dialogs.converter.show = true" v-if="lead.status != 'close'">
                    <v-list-item-title>
                      <v-icon small>mdi-database-check</v-icon>
                      Convertir le lead
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="dialogs.closer.show = true" v-if="lead.status == 'open'">
                    <v-list-item-title>
                      <v-icon small>mdi-database-check
                      </v-icon
                      >
                      Cloturer le lead
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="setStatusLead('open')" v-else>
                    <v-list-item-title>
                      <v-icon small>mdi-database-check
                      </v-icon
                      >
                      Réouvrir le lead
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="dialogs.contacts.show = true">
                    <v-list-item-title>
                      <v-icon small>edit</v-icon>
                      <span>Ajouter des contacts</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="sendDirectMail()">
                    <v-list-item-title>
                      <v-icon small>mail</v-icon>
                      <span>Mail au client</span>
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </collapse-panel>
              <booking-customer-details style="border: 1px solid lightgray;" v-if="lead"
                                        :booking="lead"
                                        :kagnots="null"
                                        :noTags="true"
                                        :fillHeight="false"
                                        class="mt-2"
                                        urlOverride="/api/lead/customer"
                                        urlSnapchotCustomer="/api/lead"
              ></booking-customer-details>
              <collapse-panel :collapsable="false" :loading="loading" v-if="lead" :fill-height="false" class="mt-2">
                <div slot="title">
                  <h3>
                    Historique
                    <span class="caption">({{ actions.length }})</span>
                  </h3>
                </div>

                <div slot="menu">
                  <v-list-item :disabled="lead.status == 'close'" @click="dialogs.action.show = true">
                    <v-list-item-title class="">
                      <v-icon small>mdi-database-check
                      </v-icon>
                      <span>Ajouter une action</span>
                    </v-list-item-title>
                  </v-list-item>
                </div>

                <div slot="content">
                  <div v-if="actions.length > 0">
                    <div v-for="(action, i) in actions" :key="action.id" class="my-2 px-3">
                      <div class="">
                        <div class="d-flex justify-space-between" style="height:1.5em;">
                          <div class=" items-center"
                               :class="action.type !== 'empty' ? 'font-weight-medium' : ' grey--text'">
                            <v-icon class="mr-1" size="medium">{{ getIcon(action) }}</v-icon>
                            {{ $t("lead.action." + action.type) }}
                          </div>
                          <div class="d-flex caption" v-if="action.type !== 'empty'">
                            <p class=" grey--text lighten-1">
                              {{
                                _parseAndFormatDate(action.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy hh'h'mm")
                              }}</p>
                            <p v-if="action.user" class="ml-1">• {{ userName(action.user) }}</p>
                          </div>
                        </div>
                        <div class="caption mb-3 ">{{ action.message }}</div>
                        <v-divider v-if="i < actions.length - 1" class="mt-4"/>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <span class="grey--text">
                      {{ $t("lead.action.empty") }}
                    </span>
                  </div>
                </div>
              </collapse-panel>
            </v-col>
          </v-row>
        </v-col>
        <!-- ------------------- right bloc -------------------  -->
        <v-col cols="8" class="pl-1">
          <collapse-panel :collapsable="false" :loading="loading" v-if="lead" class="" :fill-height="false">
            <div slot="title">
              <h3>Liste des devis</h3>
            </div>
            <div slot="menu">
              <v-list-item :disabled="lead.status == 'close'" @click="$refs.bookingAddDialog.toggle()">
                <v-list-item-title>
                  <v-icon>mdi-folder-plus</v-icon>
                  <span>Créer un devis</span>

                </v-list-item-title>
              </v-list-item>

            </div>
            <template slot="header">
              <v-data-table
                  :headers="quotationsHeaders"
                  :items="quotations"
                  :items-per-page="1000"
                  hide-default-footer
                  no-data-text="Aucuns devis rattaché à ce lead pour le moment"
                  show-select
                  item-key="bookingId"
                  :single-select="false"
                  class="tbl">

                <template #header.data-table-select="{item}">

                  <v-checkbox :value="selectedQuotes.length == quotations.length"
                              @click="selectAllQuotes()"></v-checkbox>
                </template>

                <template #item="{ item }">
                  <tr
                      @click.ctrl="goToBooking(item.bookingId, true)"
                      @click.meta="goToBooking(item.bookingId, true)"
                      @click.exact="goToBooking(item.bookingId)">
                    <!---->
                    <td @click.stop>
                      <v-checkbox v-model="item.selected"></v-checkbox>
                    </td>
                    <td class="text-left font-weight-regular">
                      <div class="d-flex flex-column">
                        <span class="caption grey--text text-lighten-2">#{{ item.bookingId }}</span>
                        <site-label :sitename="item.virtualSite"></site-label>
                      </div>
                    </td>

                    <td class="text-left font-weight-regular">
                      <div class="d-flex flex-column">
                    <span>{{
                        _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy")
                      }}</span>
                        <span
                            class="caption grey--text text-lighten-2">{{
                            _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "hh'h'mm")
                          }}</span>
                      </div>
                    </td>
                    <td class="text-left font-weight-regular">
                      <div class="d-flex flex-column">
                        <span>{{ item.startDate }}</span>
                        <span class="caption grey--text text-lighten-2">{{
                            item.stopDate
                          }} •{{ duration(item) }}n</span>
                      </div>
                    </td>
                    <td class="text-left font-weight-regular">

                      {{ item.flight.length > 0 ? item.flight.join(", ") : "-" }}
                    </td>
                    <td class="text-left font-weight-regular">
                      <v-icon v-if="item.transfer">mdi-bus-side</v-icon>
                      <span v-else>-</span>
                    </td>
                    <td class="text-left font-weight-regular">
                      {{ item.hotelsName.join(", ") }}
                    </td>
                    <td class="text-left font-weight-regular">
                      <div class="d-flex flex-column">
                        <span class="caption">{{ item.nbAdult }}AD</span>
                        <span class="caption" v-if="item.nbChild > 0">{{ item.nbChild }}CH</span>
                      </div>
                    </td>
                    <td class="text-right font-weight-regular">
                      <p
                          class="d-flex text-truncate flex-column"
                          :class="lead.budget > 0 && item.ca > lead.budget ? 'orange--text': ''">
                        {{ item.ca | currency("EUR") }}
                        <span class="body-2 grey--text">
                              {{ item.purchase | currency("EUR") }}
                              <span class="caption ml-1 grey--text text--lighten-1">{{
                                  item.markup | percent
                                }}</span>
                            </span>
                      </p>
                    </td>
                    <td class="text-right font-weight-regular" @click.stop>
                      <v-chip @click="openDuplicationDialog(item)"
                              label
                              flat
                              small
                              outlined
                              class="grey lighten-2 blue--text text--lighten-2 mr-1"
                      >
                        <v-icon small>mdi-content-duplicate</v-icon>
                      </v-chip>
                    </td>

                  </tr>
                </template>
              </v-data-table>
            </template>
          </collapse-panel>
        </v-col>
      </v-row>

      <booking-add ref="bookingAddDialog" :afterCreationFn="rattachCustomer"/>

      <smart-dialog
          v-model="dialogs.infos.show"
          title="Éditer lead"
      >
        <LeadCreationDialog :lead-info="lead" @done="() => closeEditionDialog()"/>
        <template title="actions">
          <v-card-actions class="actions-btn mt-4">
            <v-spacer/>
            <smart-btn type="submit" form="creation-lead">
              <v-icon small class="mr-1">mdi-content-save</v-icon>
              Sauvegarder
            </smart-btn>
          </v-card-actions>
        </template>
      </smart-dialog>
      <smart-dialog
          v-model="dialogs.action.show"
          title="Ajouter une action"
      >
        <lead-action-creation :lead="lead" @done="() => closeActionDialog()" v-if="dialogs.action.show"/>
      </smart-dialog>
      <smart-dialog
          v-model="dialogs.closer.show"
          title="Cloturer le lead"
      >
        <v-form id="close-form" class="pa-4 mb-4">
          <v-select v-if="lead" v-model="lead.failure"
                    :items="failTypes"
                    item-value="id"
                    item-text="label"
                    label="Raison de la cloture"
                    dense
                    hide-details
                    placeholder=""/>
        </v-form>
        <template #actions>
          <v-card-actions class="actions-btn ">
            <v-spacer/>
            <smart-btn @click.native="setStatusLead('close')">
              Valider
            </smart-btn>
          </v-card-actions>
        </template>

      </smart-dialog>
      <smart-dialog
          v-model="dialogs.converter.show"
          title="Convertir le lead">
        <v-form id="converter-form" class="pa-4 mb-4"
                v-on:submit.prevent="closeLeadWithBookingId">
          <v-text-field v-if="lead" v-model="lead.bookingIdCloseOrigin"
                        label="Ref dossier reservé"
                        dense
                        :rules="rules.number()"
                        hide-details/>
          <span class="caption text--red" v-if="dialogs.converter.error">{{ dialogs.converter.error }}</span>
        </v-form>
        <template #actions>
          <v-card-actions class="actions-btn ">
            <v-spacer/>
            <smart-btn type="submit" v-if="lead" form="converter-form"
                       :disabled="isNaN(lead.bookingIdCloseOrigin) || (lead.bookingIdCloseOrigin && lead.bookingIdCloseOrigin.length == 0)">
              Valider
            </smart-btn>
          </v-card-actions>
        </template>

      </smart-dialog>
      <smart-dialog v-model="dialogs.quotesRemove.show" title="Supprimer devis du lead">
        <div class="pa-4">
          Voulez vous bien retirer du lead ces devis ?
        </div>
        <template #actions>
          <v-card-actions class="actions-btn ">
            <v-spacer/>
            <smart-btn secondary @click.native="dialogs.quotesRemove.show = false">
              Annuler
            </smart-btn>
            <smart-btn action="delete" @click.native="detachQuotes(null, null)">
              Supprimer
            </smart-btn>
          </v-card-actions>
        </template>
      </smart-dialog>
      <smart-dialog v-model="dialogs.booking.show" title="Rattacher un dossier" @close="resetRattachmentDialog()">
        <div class="pa-4">
          <v-text-field dense
                        type="number"
                        v-model="dialogs.booking.id"
                        label="Référence dossier"/>
          <div v-if="alreadyAttached.msg != null" v-html="alreadyAttached.msg"
               class="d-flex flex-column"></div>
        </div>
        <template #actions>
          <v-card-actions class="actions-btn ">
            <v-spacer/>
            <smart-btn secondary @click.native="dialogs.booking.show = false">
              Annuler
            </smart-btn>
            <smart-btn v-if="alreadyAttached.code === 'quote.already.attached' "
                       @click.native="detachAndrattachBooking()"
                       :loading="dialogs.booking.loading">
              Rattacher
            </smart-btn>
            <smart-btn v-else @click.native="rattachBooking()" :loading="dialogs.booking.loading">
              Valider
            </smart-btn>
          </v-card-actions>
        </template>
      </smart-dialog>
      <smart-dialog
          title="Message au client"
          v-model="dialogs.mail.show"
          :width="800"
          v-if="lead"
      >
        <mail-composer
            :tos="dialogs.contacts.data.length > 0 ? [lead.customer.email, ...dialogs.contacts.data] : [lead.customer.email]"
            :loading="dialogs.mail.loading"
            :site-id="1"
            :default-internal-attachment="dialogs.mail.type ? [dialogs.mail.type] : []"
            :subject="dialogs.mail.subject || 'réfs. #' + selectedQuotes.map(q => q.bookingId).join(', #')"
            :message="dialogs.mail.message"
            @send="sendEmail"
            showInternalAttachment
        />
      </smart-dialog>
      <smart-dialog title="Mails de contact"
                    v-model="dialogs.contacts.show"
                    :width="500">

        <v-combobox
            class="ma-4"
            v-model="dialogs.contacts.data"
            chips
            deletable-chips
            label="Adresses mail"
            multiple
            hide-details
            append-icon
            :rules="[...rules.nonEmptyArray(), ...rules.emailArray()]"
        ></v-combobox>

        <template #actions>
          <smart-btn @click.native="saveContacts">Enregistrer</smart-btn>
        </template>
      </smart-dialog>
      <smart-dialog v-model="dialogs.duplicate.show" :width="320"
                    v-on:close="dialogs.duplicate.booking = null"
                    title="Dupliquer le dossier">
        <booking-duplication-dialog
            v-if="dialogs.duplicate.booking"
            :booking="dialogs.duplicate.booking"
            :show="dialogs.duplicate.show"
            :onDuplicate="(data) => afterDuplicate(data)"/>

      </smart-dialog>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout.vue";
import {get, post} from "@/utils/api";
import SiteLabel from "@/components/commons/SiteLabel.vue";
import ScoreLead from "@/components/booking/ScoreLead.vue";
import LeadCreationDialog from "@/views/lead/LeadCreationDialog.vue";
import CollapsePanel from "@/components/commons/CollapsePanel.vue";
import BookingCustomerDetails from "@/components/booking/BookingCustomerDetails.vue";
import {EventBus} from "@/components/commons/event-bus";
import BookingAdd from "@/components/booking/BookingAdd.vue";
import LeadActionCreation from "@/views/lead/LeadActionCreation.vue";
import MailComposer from "@/components/commons/MailComposer.vue";
import FormMixin from "@/components/mixins/FormMixin";
import BookingDuplicationDialog from "@/components/booking/BookingDuplicationDialog.vue";


export default {
  name: "Lead",
  components: {
    BookingDuplicationDialog,
    MailComposer,
    LeadActionCreation,
    BookingAdd,
    SiteLabel,
    BookingCustomerDetails,
    ScoreLead,
    CollapsePanel,
    Layout,
    LeadCreationDialog,
  },
  mixins: [FormMixin],
  data() {
    return {
      lead: null,
      quotations: [],
      actions: [],
      failTypes: [],
      users: [],
      quotationsHeaders: [
        {
          text: "Site",
          value: "bookingId",
          align: "left",
          sortable: true,
        },
        {
          text: "Résa",
          value: "creationDate",
          align: "left",
          sortable: true,
        },
        {
          text: "Séjour",
          value: "startDate",
          align: "left",
          sortable: true,
        },
        {
          text: "Vols",
          value: "flight",
          align: "left",
          sortable: true,
        },
        {
          text: "Trsf.",
          value: "transfer",
          align: "left",
          sortable: true,
        },
        {
          text: "Hotels",
          value: "hotelsName",
          align: "left",
          sortable: true,
        },
        {
          text: "Pax",
          value: "pax",
          align: "left",
          sortable: false,
        },
        {
          text: "Montant",
          value: "ca",
          align: "left",
          sortable: true,
        },
        {
          text: "",
          value: "",
          align: "",
          sortable: false
        },
      ],
      loading: true,
      bookingToDuplicate: null,
      alreadyAttached: {
        id: null, msg: null,code:null
      },
      dialogs: {
        duplicate: {
          show: false,
          id: null
        },
        converter: {
          error: null,
          show: false
        },
        closer: {
          show: false
        },
        infos: {
          show: false
        },
        action: {
          show: false
        },
        quotesRemove: {
          show: false,
          loading: false
        },
        mail: {
          show: false,
          loading: true,
          subject: null,
          type: 'quotation',
          message: "",
          to: [],
        },
        contacts: {
          show: false,
          data: [],
        },
        booking: {
          show: false,
          id: null
        }
      },
      rattachCustomer: (idBooking) => {
        if (this.lead.customer.id != null) {
          return get(`/api/booking/attachcustomer/${idBooking}/${this.lead.customer.id}`).json(() => {
            return Promise.resolve()
          })
        } else {
          return Promise.resolve()
        }
      },
    }
  },
  created() {
    this.init()
    EventBus.$on("reloadLead", (msg) => {
      this.init();
    });

  },
  computed: {
    maxPrice() {
      if (this.quotations.length > 0) {
        return this.quotations.map(q => q.ca).sort((a, b) => b - a)[0];
      } else return "-"
    },
    htmlMessage() {
      return `<p>${this.lead.message.replaceAll("\n", "<br/>")}</p>`
    },
    selectedQuotes() {
      return this.quotations.filter(quote => quote.selected)
    }
    ,
  },
  methods: {
    init() {
      const url = `/api/lead/${this.$route.params.id}`

      const loadingLead = this.loadLead()

      const loadingQuotes = get(url + "/quotations").json((rs) => {
        this.quotations = rs.map(r => {
          return {...r, selected: false}
        })
      })

      const loadActions = get(url + "/actions").json((rs) => {
        if (rs.length > 0) {
          this.actions = rs
        } else {
          this.actions = []
        }


      })

      const loadFailTypes = get("/api/lead/failure/types").json((rs) => {
        this.failTypes = rs.map(f => {
          return {id: f, label: this.$t("lead.fail." + f)}
        })
      })

      const loadUsers = get("/api/search/users").json(rs => {
        this.users = rs
      })

      return Promise.all([
        loadingLead, loadingQuotes, loadActions, loadFailTypes, loadUsers
      ]).then(() => {
        this.loading = false
      });
    },
    loadLead() {
      const url = `/api/lead/${this.$route.params.id}`
      return get(url).json((rs) => {
        if (rs.customer == null) {
          rs.customer = {id: null}
        }
        this.lead = rs
        this.dialogs.contacts.data = this.lead.contacts.map(obj => obj.mail)
      })
    },
    closeDuplicateDialogs() {

      this.dialogs.duplicate.show = false
      this.init()
    },
    openDuplicationDialog(item) {
      const dateFormatted = this._parseAndFormatDate(item.startDate, "dd/MM/yyyy", "dd/MM/yyyy 00:00:00")
      let booking = {
        ...item,
        id: item.bookingId,
        startDate: dateFormatted
      }
      this.bookingToDuplicate = item.bookingId
      this.dialogs.duplicate = {
        booking: booking,
        show: true,
        id: item.id
      }
    },
    afterDuplicate(data) {
      this.dialogs.booking.id = data
      this.addAction(
          {
            creationDate: null,
            type: 'update',
            message: `Duplication du dossier #${this.bookingToDuplicate} vers le #${data}`,
            leadId: this.lead.id,
            user: this.users.find(u => u.id === this.currentUser.id),
            bookingId: null,
            phonecallId: null,
          }
      )
      this.loadingDetail = false;
      this.bookingToDuplicate = null
      this.init()
    },
    detachQuotes(otherLead, bookingId) {
      this.dialogs.quotesRemove.loading = true
      const url = `/api/lead/${otherLead != null ? otherLead : this.lead.id}/detach/quotations`
      post(url, {ids: bookingId != null ? [bookingId] : this.selectedQuotes.map(quote => quote.bookingId)}).json((nb) => {
        this.init().then(() => {
          this.dialogs.quotesRemove.show = false
          EventBus.$emit("toaster-msg", `Détachement de ${nb} devis effectuée`)
          if (otherLead != null && bookingId != null) {
            this.rattachBooking()
          }
          return nb
        }).catch(err => EventBus.$emit("toaster-error", "Erreur lors de la suppression"))
      })
    },
    detachAndrattachBooking() {
      this.dialogs.booking.loading = true
      this.detachQuotes(this.alreadyAttached.id, this.dialogs.booking.id)
      this.alreadyAttached = {id: null, msg: null}
    },
    rattachBooking() {
      console.log(this.lead.id)
      this.dialogs.booking.loading = true
      post(`/api/lead/${this.lead.id}/add/quotations/${this.dialogs.booking.id}`)
          .badRequest((err) => {
            console.log(err)
            const error = JSON.parse(err.toString().split(": ")[1])
            this.alreadyAttached.id = error.leadId
            this.alreadyAttached.code = error.msg

            switch (error.msg) {
              case "quote.already.attached":
                this.alreadyAttached.msg = `<span class="red--text">${this.$t('lead.attachFailed.already', {id:error.leadId})}</span>
                    <span>${this.$t('lead.attachFailed.rattach')}</span>`
                break;
              case "quote.not.hotelissima":
                this.alreadyAttached.msg = `<span class="red--text">${this.$t('lead.attachFailed.notHotelissima')}</span>`
                break;
              case "quote.not.exist":
                this.alreadyAttached.msg = `<span class="red--text">${this.$t('lead.attachFailed.notExist')}</span>`
                break;
              case "quote.not.added":
                this.alreadyAttached.msg = `<span class="red--text">${this.$t('lead.attachFailed.notAdded')}</span>`
                break;
              default:
                this.alreadyAttached.msg = `<span class="red--text">{{error.msg}}</span>`

            }
            EventBus.$emit("toaster-error", "Impossible!")
            this.dialogs.booking.loading = false
          })
          .json(() => {
            this.init().then(() => {
              this.dialogs.booking.loading = false
              this.dialogs.booking.id = null
              this.dialogs.booking.show = false
            })

          })
    },
    goToBooking(id, newTab = false) {
      if (newTab) {
        let route = this.$router.resolve({
          name: "Booking",
          params: {id: id},
        });
        window.open(route.href, "_blank");
        return false;
      } else {
        this.$router.push({name: "Booking", params: {id: id}});
      }
    },
    duration(item) {
      const start = this._parseDate(item.startDate, "dd/MM/yyyy")
      const stop = this._parseDate(item.stopDate, "dd/MM/yyyy")
      return this._duration(start, stop, 'days')
    },
    async closeLeadWithBookingId() {
      this.dialogs.converter.error = null
      await get(`/api/booking/${this.lead.bookingIdCloseOrigin}`).json(async (rs) => {
        if (rs.status === "Success") {
          this.lead.bookingIdCloseOrigin = parseInt(this.lead.bookingIdCloseOrigin)
          await post(`/api/lead/${this.lead.id}`, this.lead).json(async (rs) => {
            this.lead = rs
            await get(`/api/lead/${this.lead.id}/convert`).res(() => {
              this.dialogs.converter.show = false
              EventBus.$emit("toaster-msg", `Lead cloturé`)

              this.init()
            })
          })

        } else {
          this.dialogs.converter.error = `les dossier n°${this.lead.bookingIdCloseOrigin} n'existe pas`
        }
      })
    },
    addAction(data) {
      post("/api/lead/add/action", data).json(() => {
        this.init()
      })
    },
    closeEditionDialog() {
      this.dialogs.infos.show = false
      this.loadLead()
    },
    closeActionDialog() {
      this.dialogs.action.show = false
    },
    setStatusLead(to) {
      let toStatus = ''
      let toastMsg = ''
      switch (to) {
        case 'open':
          toastMsg = 'réouvert'
          toStatus = "open"
          break;
        case 'close':
          toastMsg = 'cloturé'
          toStatus = "close"
          break;
        case 'pending':
          toastMsg = 'en attente client'
          toStatus = "pending"
          break;
      }
      let endPath = this.lead.failure && toStatus === 'close' ? "/" + this.lead.failure : ""

      get(`/api/lead/${this.lead.id}/${toStatus}${endPath}`).res(() => {
        this.dialogs.closer.show = false
        EventBus.$emit("toaster-msg", `Lead ${toastMsg}`)
        this.init()

      })
    },
    resetRattachmentDialog() {
      this.alreadyAttached = {id: null, msg: null}
      this.dialogs.booking.id = null
      this.dialogs.booking.loading = false
    },
    getIcon(action) {
      switch (action.type) {
        case 'update':
          return "mdi-update";
        case 'call':
          return "mdi-phone";
        case 'email':
          return "mdi-mail";
        case 'proposition':
          return "mdi-currency-eur";
      }
    },
    userName(user) {
      if (this.lead.user && user == null) {
        return `${this.lead.user.firstname[0]}. ${this.lead.user.lastname}`
      } else if (user != null) {
        return `${user.firstname[0]}. ${user.lastname}`
      } else {
        return "Aucun agent affecté"
      }
    },
    sendEmail(mail) {
      this.dialogs.mail.loading = true;
      const body = {
        email: {...mail, externalAttachments: []},
        leadId: this.lead.id,
      }


      let url = "/api/lead/mail/sendQuotations";
      if (this.dialogs.mail.type === 'quotation') {
        body.bookingIds = this.selectedQuotes.map(quote => quote.bookingId)

      } else if (!this.dialogs.mail.type) {
        url = "/api/lead/mail/send"
        body.bookingIds = this.quotations.map(quote => quote.bookingId)
      }

      post(url, body)
          .json((r) => {
            EventBus.$emit("toaster-msg", "Email envoyé");
            this.init()
            return r;
          })
          .then(() => {
            this.dialogs.mail.loading = false;
            this.dialogs.mail.show = false;
          });
    },
    saveContacts() {
      const body = this.dialogs.contacts.data.map(email => {
        return {mail: email}
      })

      post(`/api/lead/${this.lead.id}/contacts`, body).json(rs => {
        this.dialogs.contacts.show = false
        this.init()
      })
    },
    sendQuotes() {
      this.dialogs.mail.message = this.buildQuoteMail()
      this.dialogs.mail.type = 'quotation'
      this.dialogs.mail.subject = null
      this.dialogs.mail.show = true
    },
    sendDirectMail() {
      this.dialogs.mail.subject = 'Contact Hotelissima'
      this.dialogs.mail.type = null
      this.dialogs.mail.message = null
      this.dialogs.mail.show = true
    },
    buildQuoteMail() {

      let destinations = this.quotations.filter(quote => quote.selected)
          .flatMap(q => q.destinations.map(d => d.customerName)).filter(
              (value, index, array) => {
                return array.indexOf(value) === index;
              })
      if (destinations.length > 1) {
        const combined = destinations.at(-2) + " et " + destinations.at(-1)
        destinations.splice(-2, 2)
        destinations.push(combined)

      }

      return `
      <p>Je fais suite à notre conversation téléphonique.</p>
      <p>Je vous remercie de trouver en pièce jointe ${this.selectedQuotes.length} devis à <strong>${destinations.join(", ")}</strong>.</p>
      <p>Si vous souhaitez apporter des modifications n'hésitez pas à revenir vers moi.</p>
        <br/>
      <p>Je reste à votre disposition pour tout complément d'information</p>
      <p>Cordialement</p>
      <p>${this.lead.user.firstname}</p>
      `
    },
    selectAllQuotes() {

      if (this.quotations.length != this.selectedQuotes.length) {
        this.quotations.map(q => q.selected = true)
      } else {
        this.quotations.map(q => q.selected = false)
      }
    },
  },
  destroyed() {
    EventBus.$off('reloadLead')
  }

}
</script>
<style>
</style>