<template>
  <v-btn
    :loading="loading"
    v-model="value"
    v-bind="options"
    class="custom-btn elevation-0"
    :color="color.bg"
    :class="[color.text]"
    ><slot></slot
  ></v-btn>
</template>
<script>
export default {
  name: "Btn",
  props: {
    value: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    tertiary: { type: Boolean, default: false },
    action: { type: String, default: "normal" },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    options: { text: true },
  }),
  watch: {
    $attrs: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.options = { ...this.options, ...newVal, ...this.customConfig };
      },
    },
    value(newVal) {
      this.$emit("input", newVal);
    },
  },
  computed: {
    customConfig() {
      return {
        outlined: this.secondary,
        text: this.tertiary,
      };
    },
    color() {
      if (this.$attrs.color) {
        return this.$attrs.color;
      }

      switch (this.action) {
        case "normal":
          return { bg: "blue lighten-1", text: "white--text" };
        case "delete":
          return { bg: "red lighten-2", text: "white--text" };
        default:
          return { bg: "grey", text: "" };
      }
    },
  },
};
</script>
<style scoped>
.custom-btn {
  border-radius: 5px;
}
</style>
