<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step step="1" editable>Modifier chambre</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2">Valider</v-stepper-step>
    </v-stepper-header>
    <v-stepper-content step="1" class="pa-0">
      <v-toolbar big>
        <smart-picker :inputOpts="{ label: $t('begin'), class: 'ml-1' }" v-model="ctx.startDate"></smart-picker>
        <smart-picker :inputOpts="{ label: $t('begin'), class: 'ml-1' }" v-model="ctx.stopDate"></smart-picker>
        <v-spacer></v-spacer>
        <booking-guest-repartition v-model="ctx.roomsRepartition[0].guests"
          :guests="guests"></booking-guest-repartition>
        <v-spacer></v-spacer>
        <v-btn @click="search">Rechercher</v-btn>
      </v-toolbar>

      <div v-if="hotelprice != null">
        <v-data-table :headers="headers" :items="items" hide-default-footer class="pricegrid elevation-1"
          item-key="roomName" :options.sync="pagination"
          :footer-props="{ 'items-per-page-options': pagination.rowsPerPageItems, 'items-per-page-text': 'Lignes par page' }">
          <template #body="{ items }">
            <tbody>
              <tr v-for="(item, itemIndex) in items" :key="itemIndex">
                <td>
                  {{ item.roomName }}
                  <ul class="list-horizontal pa-0 grey--text" v-for="(r, i) in item.rooms" :key="'room' + i">
                    <li v-if="r.surface">{{ r.surface }}m2</li>
                    <li v-if="r.view">Vue {{ r.view }}</li>
                    <li v-for="(s, i) in r.services" :key="'service' + i">{{ s.name }}</li>
                    <li v-for="(a, i) in r.arguments" :key="'args' + i">{{ a }}</li>
                  </ul>
                </td>
                <td class="text-center" v-for="(meal, i) in item.meals" :key="i">
                  <div v-if="meal" class="selectable">
                    <span v-if="meal.prices" @click="select(meal)">
                      <span class="price">
                        {{ meal.prices.price.toLocaleString() }}{{ meal.prices.currency.symbol }}
                        <span class="status" :class="[meal.availability.status]"></span>
                      </span>
                      <div class="promo" if="meal.prices.stroke > meal.prices.price">
                        <span class="stroke">{{ meal.prices.stroke.toLocaleString() }}</span>
                        -{{ ((meal.prices.stroke - meal.prices.price) * 100 / meal.prices.stroke).toFixed() }}%
                      </div>
                    </span>

                    <span v-else class="soldout">
                      <div>
                        {{ meal.availability.status }}
                        <span class="status" :class="[meal.availability.status]"></span>
                      </div>
                      <span class="occupable" v-if="!meal.availability.occupable">
                        <span class="stroke">occupable</span>
                      </span>
                      <span class="minStay" v-if="meal.availability.minStay != null">
                        <span class="stroke">minStay</span>
                        {{ meal.availability.minStay }}
                      </span>
                      <span v-else>-</span>
                    </span>
                  </div>
                  <div v-else>-</div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </v-stepper-content>
    <v-stepper-content step="2" class="pa-0">
      <v-row>
        <v-col cols="5">
          <ul>
            <li>Du {{ _parseAndFormatDate(prestation.start, "dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy") }} au
              {{ _parseAndFormatDate(prestation.stop, "dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy") }}</li>
            <li>
              <guest-list :guests="prestation.guests"></guest-list>
            </li>
            <li>{{ prestation.name }} / {{ prestation.mealplanName }}</li>
            <li>Contract: {{ prestation.marketId }}, Market: {{ prestation.market }}</li>
            <li>
              <v-row>
                <v-col cols="6">
                  Offres
                  <ul>
                    <li v-for="o in prestation.offers" :key="o.id">{{ o.name }} (-{{ o.amount }}€)</li>
                  </ul>
                </v-col>
                <v-col cols="6" v-if="prestation.extras && prestation.extras.length > 0">
                  Extras
                  <ul>
                    <li v-for="e in prestation.extras" :key="e.id">{{ e.name }}</li>
                  </ul>
                </v-col>
              </v-row>
            </li>
          </ul>
        </v-col>
        <v-col cols="2">
          <v-divider vertical style="margin:auto"></v-divider>
        </v-col>
        <v-col cols="5">
          <ul class="nostyle" v-if="propositionSelected">
            <li>Du {{ _parseAndFormatDate(propositionSelected.startDate, "dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy") }} au
              {{ _parseAndFormatDate(propositionSelected.stopDate, "dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy") }}</li>
            <li v-for="(roomPrice, idx) in propositionSelected.roomsPrice" :key="'room' + idx">
              <ul class="mt-2" v-for="(contractPrice, i) in roomPrice.contractsPrice" :key="'contract-' + i">
                <li>
                  <guest-list :guests="roomPrice.guests"></guest-list>
                </li>
                <li>
                  <span v-if="roomPrice.contractsPrice && roomPrice.contractsPrice.length > 1">Du
                    {{ contractPrice.startDate }}
                    au {{ contractPrice.stopDate }}</span>
                  1x {{ roomPrice.room.name }} / {{ roomPrice.meal.name }}
                </li>
                <li>Contract: {{ contractPrice.contractId }}, Market: {{ contractPrice.marketId }}</li>
                <li>
                  <v-row>
                    <v-col cols="6">
                      Offres
                      <ul>
                        <li v-for="o in contractPrice.prices.offers" :key="o.id">{{ o.name }} ({{ o.amount }}€)</li>
                      </ul>
                    </v-col>
                    <v-col cols="6" v-if="contractPrice.prices.extras && contractPrice.prices.extras.length > 0">
                      Extras
                      <ul>
                        <li v-for="e in contractPrice.prices.extras" :key="e.id">
                          <v-checkbox v-model="e.included" :disabled="e.mandatory" dense hide-details class="ma-0">
                            <template #label>{{ e.name }} (+{{ e.price }}€)</template>
                          </v-checkbox>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </li>
              </ul>
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="6">
          <div class="text-center headline">{{ prestation.price }}</div>
        </v-col>
        <v-col cols="6">
          <div class="text-center headline" v-if="propositionSelected">{{ propositionPrice }}</div>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" class="text-right">
          <v-btn @click="confirm">Modifier la prestation</v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </v-stepper>
</template>
<script>
import _ from "lodash";

import RoomRepartition from "@/components/commons/RoomRepartition";
import { EventBus } from "@/components/commons/event-bus";
import { post } from "@/utils/api";
import GuestList from "@/components/commons/GuestList";
import BookingGuestRepartition from "../../commons/BookingGuestRepartition";

export default {
  name: "ModifyRoom",
  components: { BookingGuestRepartition, GuestList, RoomRepartition },
  mixins: [],
  props: {
    booking: { type: Object, required: true },
    prestation: { type: Object, required: true },
    guests: { type: Array, required: true },
  },
  data: () => ({
    step: 1,
    ctx: {
      hotelId: null,
      bookingDate: "",
      startDate: "",
      stopDate: "",
      roomsRepartition: [],
      declarations: [],
      roomTypeIds: [],
      mealPlanTypeIds: [],
      extras: [],
      hotelSelections: [],
      env: {
        siteId: 1,
        langId: 1,
        priceClassTypeId: 2,
        strokePriceClassTypeId: 3,
        clientCountry: "FR",
        debug: false,
        withCache: false,
      },
    },
    allMeals: [
      { name: "Hébergement seul", mealPlanTypeId: 4 },
      { name: "Petit-déjeuner", mealPlanTypeId: 3 },
      { name: "Demi-Pension", mealPlanTypeId: 1 },
      { name: "Pension complète", mealPlanTypeId: 2 },
      { name: "All inclusive", mealPlanTypeId: 5 },
    ],
    headers: [{ text: "", sortable: false, align: "left" }],
    hotelprice: null,
    items: [],
    propositionSelected: null,
    pagination: {
      rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
      page: 1,
      sortBy: [],
      sortDesc: [false],
    },
  }),
  computed: {
    propositionPrice(){

      const extras = this.propositionSelected.roomsPrice.flatMap((r) =>
          r.contractsPrice.flatMap((c) =>
            c.prices.extras
          )
        )
        
      const extrasPrice = extras.filter(e => e.included).reduce((total, e) => total+e.price, 0)
      return this.propositionSelected.prices.price + extrasPrice
    }
  },
  watch: {
    prestation() {
      this.init();
    },
  },
  created() {
    this.init();
    this.allMeals.map((meal) => {
      this.headers.push({
        text: meal.name,
        mealPlanTypeId: meal.mealPlanTypeId,
        sortable: false,
        align: "center",
        width: 150,
      });
    });
  },
  methods: {
    init() {
      this.ctx = Object.assign({}, this.ctx, {
        hotelId: this.prestation.hotelId,
        bookingDate: this._nowFormatted(
          this.datePatternConfig.serverLocalDateTime
        ),
        startDate: this.prestation.start.slice(0, 10),
        stopDate: this.prestation.stop.slice(0, 10),
        roomsRepartition: [
          { guests: this.prestation.guests, repartition: "SAMEROOM" },
        ],
        declarations: this.booking.context.declarations,
        env: {
          siteId: this.booking.context.site.id,
          langId: this.booking.context.lang.id,
          priceClassTypeId: this.booking.context.priceClassType.id,
          strokePriceClassTypeId: this.booking.context.strokePriceClassType.id,
          clientCountry: this.booking.context.countryCode,
          debug: false,
          withCache: false,
        },
      });

      this.search();
    },
    search() {
      post(`/api/prestation/add/search`, {
        search: { kind: "hotel", name: "", id: this.ctx.hotelId },
        ctx: this.ctx,
      })
        .badRequest((err) => {
          EventBus.$emit(
            "toaster-msg",
            JSON.parse(err.null).errors.map((e) => JSON.parse(e))
          );
        })
        .json((data) => {
          this.hotelprice = data[0];

          this.items = [];
          this.hotelprice.hotel.propositions.map((p) => {
            const roomName = p.rooms.map((r) => r.name).join(", ");
            const rooms = _.intersectionBy(
              this.hotelprice.hotel.rooms,
              p.rooms,
              "roomId"
            );

            var index = _.findIndex(this.items, (i) => {
              return i.roomName === roomName;
            });
            if (index === -1) {
              this.items.push({
                roomName: roomName,
                rooms: rooms,
                propositions: [p],
              });
            } else {
              this.items[index].propositions.push(p);
            }
          });

          this.items = _.map(this.items, (item) => {
            item.meals = _.map(this.allMeals, (meal) => {
              const prop = item.propositions.find((p) => {
                return (
                  _.findIndex(p.meals, (m) => {
                    return m.mealPlanTypeId === meal.mealPlanTypeId;
                  }) != -1
                );
              });

              return prop;
            });
            return item;
          });
        });
    },
    select(item) {
      this.propositionSelected = item;
      this.step = 2;
    },
    confirm() {
      const ctx = Object.assign({}, this.ctx, {
        startDate: this.propositionSelected.startDate.split(" ")[0],
        stopDate: this.propositionSelected.stopDate.split(" ")[0],
        roomTypeIds: _.map(this.propositionSelected.rooms, (r) => r.roomTypeId),
        mealPlanTypeIds: _.map(
          this.propositionSelected.meals,
          (r) => r.mealPlanTypeId
        ),
        extras: this.propositionSelected.roomsPrice.flatMap((r) =>
          r.contractsPrice.flatMap((c) =>
            c.prices.extras.map((e) => [e.id, e.included])
          )
        ),
      });
      post(
        "/api/prestation/modify/hotel?prestationId=" +
        this.prestation.id +
        "&hash=" +
        this.propositionSelected.hash,
        ctx
      ).res(() => {
        EventBus.$emit("reloadbooking", {});
        this.$emit("close");
      });
    },
  },
};
</script>
<style lang="less">
.selected {
  background-color: #4285f4;
  color: #fff;
  //text-shadow:1px 1px 2px black;
  z-index: 1;
  padding: 5px;
}

.selectable {
  cursor: pointer;
}

.list-horizontal li {
  display: inline-block;
}

.list-horizontal li:before {
  content: "\00a0\2022\00a0\00a0";
  color: #999;
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
}

.list-horizontal li:first-child:before {
  content: "";
}
</style>
