<template>
  <v-card v-if="propositionSelected">
    <v-card-title class="font-weight-bold">
      <v-row>
        <v-col cols="8">
          <div>{{ hotelprice.hotel.name }}</div>
          <div>
            {{ propositionSelected.roomsPrice.length }}x {{ roomsName }} /
            {{ mealsName }}
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div>{{ propositionPrice.toLocaleString() }}€</div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <ul class="nostyle">
        <li>
          Du
          {{
            _parseAndFormatDate(
              propositionSelected.startDate,
              "dd/MM/yyyy HH:mm:ss",
              "dd/MM/yyyy"
            )
          }}
          au
          {{
            _parseAndFormatDate(
              propositionSelected.stopDate,
              "dd/MM/yyyy HH:mm:ss",
              "dd/MM/yyyy"
            )
          }}
        </li>
        <li v-for="(roomPrice, roomPriceIndex) in propositionSelected.roomsPrice" :key="roomPriceIndex">
          <ul class="mt-2" v-for="(
              contractPrice, contractPriceIndex
            ) in roomPrice.contractsPrice" :key="contractPriceIndex">
            <li>
              <span v-if="roomPrice.contractsPrice.length > 1">Du {{ contractPrice.startDate }} au
                {{ contractPrice.stopDate }}</span>
              1x {{ roomPrice.room.name }} / {{ roomPrice.meal.name }}
            </li>
            <li>
              <guest-list :guests="roomPrice.guests"></guest-list>
            </li>
            <li>
              Contract: {{ contractPrice.contract.name }}, Market:
              {{ contractPrice.market.name }}
            </li>
            <li>
              <v-row>
                <v-col cols="6">
                  Offres
                  <ul>
                    <li v-for="o in contractPrice.prices.offers" :key="o.id">
                      {{ o.name }} ({{ o.amount }}€)
                    </li>
                  </ul>
                </v-col>
                <v-col cols="6" v-if="contractPrice.prices.extras.length > 0">
                  Extras
                  <ul>
                    <li v-for="e in contractPrice.prices.extras" :key="e.id">
                      <v-checkbox v-model="e.included" :disabled="e.mandatory" dense hide-details class="ma-0">
                        <template #label>{{ e.name }} (+{{ e.price }}€)</template>
                      </v-checkbox>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </li>
          </ul>
        </li>
      </ul>
    </v-card-text>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab key="calendar" ripple> <v-icon>today</v-icon>Calendrier </v-tab>
        <v-tab key="graph" ripple> <v-icon>bar_chart</v-icon>Graphique </v-tab>

        <v-tab-item key="calendar" class="pt-3">
          <price-grid :items="prices" :loading="loadingPrices" @onSelect="selectProposition">
            <template slot="cell" slot-scope="data">
              <span class="nights">{{
                _nbNights(
                  _parseDate(data.item.startDate, "dd/MM/yyyy"),
                  _parseDate(data.item.stopDate, "dd/MM/yyyy")
                )
              }}☾</span>
              <div>
                <span v-if="data.item.prices">
                  <span class="price">
                    {{ data.item.prices.price.toLocaleString()
                    }}{{ data.item.prices.currency.symbol }}
                    <span class="status" :class="[data.item.availability.status]"></span>
                  </span>
                  <div class="promo" if="data.item.prices.stroke > data.item.prices.price">
                    <span class="stroke">{{
                      data.item.prices.stroke.toLocaleString()
                      }}</span>
                    -{{
                      (
                        ((data.item.prices.stroke - data.item.prices.price) *
                          100) /
                        data.item.prices.stroke
                      ).toFixed()
                    }}%
                  </div>
                </span>
                <span v-else class="soldout">
                  <div>
                    {{ data.item.availability.status }}
                    <span class="status" :class="[data.item.availability.status]"></span>
                  </div>
                  <span class="occupable" v-if="!data.item.availability.occupable">
                    <span class="stroke">occupable</span>
                  </span>
                  <span class="minStay" v-if="data.item.availability.minStay != null">
                    <span class="stroke">minStay</span>
                    {{ data.item.availability.minStay }}
                  </span>
                  <span v-else>-</span>
                </span>
              </div>
            </template>
          </price-grid>
        </v-tab-item>
        <v-tab-item key="graph">Graphique</v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small color="primary" @click="add">Ajouter</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import _ from "lodash";
import PriceGrid from "@/components/commons/PriceGrid";
import { EventBus } from "@/components/commons/event-bus";
import GuestList from "@/components/commons/GuestList";
import { get, post } from "@/utils/api";

export default {
  name: "HotelPriceDetails",
  mixins: [],
  components: {
    PriceGrid,
    GuestList,
  },
  props: {
    bookingId: { required: true },
    bookingPrice: { required: true },
    hotelprice: { required: true },
    ctx: { required: true },
    proposition: { required: true },
    scaleStart: { type: Number, default: 3 },
    scaleStop: { type: Number, default: 2 },
  },
  data: () => ({
    tab: "calendar",
    propositionSelected: null,
    prices: [],
    loadingPrices: false,
    contracts: [],
    markets: [],
  }),
  watch: {
    proposition: {
      immediate: true,
      handler(newVal) {
        this.updateProposition(this.proposition).then(() => this.init());
      },
    },
  },
  computed: {
    roomsName() {
      return this.propositionSelected.rooms
        .map((r) => {
          return r.name;
        })
        .join(", ");
    },
    mealsName() {
      return this.propositionSelected.meals
        .map((m) => {
          return m.name;
        })
        .join(", ");
    },
    propositionPrice() {

      const extras = this.propositionSelected.roomsPrice.flatMap((r) =>
        r.contractsPrice.flatMap((c) =>
          c.prices.extras
        )
      )

      const extrasPrice = extras.filter(e => e.included).reduce((total, e) => total + e.price, 0)
      return this.propositionSelected.prices.price + extrasPrice
    }
  },
  methods: {
    init() {
      this.prices = [];
      const start = this._parseDate(this.ctx.startDate, "dd/MM/yyyy");
      const stop = this._parseDate(this.ctx.stopDate, "dd/MM/yyyy");

      // const minStart = start.clone().add(-this.scaleStart, 'days')
      const minStart = this._subDays(start, this.scaleStart);
      //const maxStart = start.clone().add(this.scaleStart, 'days')
      const maxStart = this._addDays(start, this.scaleStart);

      //const minStop = stop.clone().add(-this.scaleStop, 'days')
      const minStop = this._subDays(stop, this.scaleStop);
      //const maxStop = stop.().add(this.scaleStop, 'days')
      const maxStop = this._addDays(stop, this.scaleStop);

      //var pStart = minStart.clone()
      var pStart = minStart;
      var all = [];
      this.loadingPrices = true;

      while (this._isSameOrBefore(pStart, maxStart)) {
        var pStop = minStop;
        while (this._isSameOrBefore(pStop, maxStop)) {
          if (
            this._isSame(pStart, start, "seconds") &&
            this._isSame(pStop, stop, "seconds")
          ) {
            this.prices.push({
              start: this._formatDate(pStart, "dd/MM/yyyy"),
              stop: this._formatDate(pStop, "dd/MM/yyyy"),
              item: this.propositionSelected,
              selected: true,
            });
          } else {
            const start = this._formatDate(pStart, "dd/MM/yyyy");
            const stop = this._formatDate(pStop, "dd/MM/yyyy");

            if (this._isSameOrBefore(pStop, pStart)) {
              this.prices.push({
                start: start,
                stop: stop,
                item: null,
                selected: false,
              });
            } else {
              this.prices.push({
                start: start,
                stop: stop,
                item: void 0,
                selected: false,
              });

              const ctx = Object.assign({}, this.ctx, {
                startDate: start,
                stopDate: stop,
                roomTypeIds: _.map(
                  this.propositionSelected.rooms,
                  (r) => r.roomTypeId
                ),
                mealPlanTypeIds: _.map(
                  this.propositionSelected.meals,
                  (r) => r.mealPlanTypeId
                ),
              });

              var p = post(`/api/prestation/add/search-hotel`, ctx).json(
                (data) => {
                  const prop =
                    data.hotel.propositions.length > 0
                      ? data.hotel.propositions[0]
                      : null;

                  var index = _.findIndex(this.prices, (p) => {
                    return p.start == start && p.stop == stop;
                  });

                  this.prices[index].item = prop;
                }
              );

              all.push(p);
            }
          }
          pStop = this._addDays(pStop, 1);
        }
        pStart = this._addDays(pStart, 1);
      }
      Promise.all(all).then(
        (values) => {
          this.loadingPrices = false;
        },
        (err) => {
          this.loadingPrices = false;
        }
      );
    },
    add() {
      const ctx = Object.assign({}, this.ctx, {
        startDate: this.propositionSelected.startDate.split(" ")[0],
        stopDate: this.propositionSelected.stopDate.split(" ")[0],
        roomTypeIds: _.map(this.propositionSelected.rooms, (r) => r.roomTypeId),
        mealPlanTypeIds: _.map(
          this.propositionSelected.meals,
          (r) => r.mealPlanTypeId
        ),
        extras: this.propositionSelected.roomsPrice.flatMap((r) =>
          r.contractsPrice.flatMap((c) =>
            c.prices.extras.map((e) => [e.id, e.included])
          )
        ),
      });
      post(
        "/api/prestation/add/hotel?bookingId=" +
        this.bookingId +
        "&hash=" +
        this.propositionSelected.hash,
        ctx
      ).res((resp) => {
        this.$emit("close");
        EventBus.$emit("reloadbooking", {});
        setTimeout(() => {
          EventBus.$emit("prestation-added", {});
        }, 500);
      });
    },
    selectProposition(proposition) {
      this.prices = _.map(this.prices, (p) => {
        p.selected =
          p.item !== void 0 &&
          p.item != null &&
          p.item.hash === proposition.hash;
        return p;
      });
      this.updateProposition(proposition);
    },
    async updateProposition(proposition) {
      this.propositionSelected = await this.enhanceProposition(proposition);
    },
    async enhanceProposition(proposition) {
      if (proposition == null) return Promise.reject();

      const roomsPrice = await Promise.all(
        proposition.roomsPrice.map(async (roomPrice) => {
          const contractsPrice = await Promise.all(
            roomPrice.contractsPrice.map(async (contractPrice) => {
              const contract = await get(
                "/api/search/hotel/contract/" + contractPrice.contractId
              ).json((data) => data);
              const market = await get(
                "/api/search/hotel/market/" + contractPrice.marketId
              ).json((data) => data);

              contractPrice.contract = contract;
              contractPrice.market = market;

              return contractPrice;
            })
          );

          roomPrice.contractsPrice = contractsPrice;
          return roomPrice;
        })
      );

      proposition.roomsPrice = roomsPrice;

      return proposition;
    },
  },
};
</script>
<style lang="less">
.nights {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 9px;
  padding: 1px 0 1px 0;
  color: #29b6f6;
}

.promo,
.minStay {
  font-size: 11px;
  color: #aaa;
}

.stroke {
  text-decoration: line-through;
}

.status {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;

  &.onStock,
  &.freeSell {
    background-color: #66bb6a;
  }

  &.onRequest {
    background-color: #f9a825;
  }

  &.soldout,
  &.stopSell,
  &.closed {
    background-color: #c62828;
  }
}

.price {
  font-weight: normal;
}

ul.nostyle {
  list-style: none;
}
</style>
