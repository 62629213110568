<template>
  <div>
    <div>
      <div v-if="duplicated">
        <v-row class="pa-2 text-center">
          <v-col cols="12">
            Le dossier a été dupliqué avec l'identifiant:
            <b>{{ newBooking }}</b>
          </v-col>
          <v-col cols="12">
            <div>
              <v-btn depressed small color="primary" @click="openDuplicate"
              >Voir le dossier
              </v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-form class="pa-4 " v-on:submit.prevent="valid" id="datePushBackForm">
          <div v-if="loading" class="d-flex justify-center">
            <v-progress-circular
                :size="50"
                color="primary"
                :indeterminate="true"
            ></v-progress-circular>
          </div>
          <div v-else>
            <span class="font-weight-bold">Nouvelle date de départ</span>
          <v-date-picker class="mt-4" locale="FR-fr" no-title v-model="newStartDate"
                         :min="_formatDate(new Date(), 'yyyy-MM-dd')"
          />
            </div>
        </v-form>
      </div>
    <v-card-actions class="actions-btn" v-if="!duplicated">
            <v-spacer/>
            <smart-btn primary form="datePushBackForm" type="submit" :loading="loading">{{
                $t("duplicate")
              }}
            </smart-btn>
          </v-card-actions>

    </div>
  </div>
</template>
<script>

import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "BookingDuplicationDialog",
  props: {
    booking: {type: Object, required: true},
    show: {type: Boolean, default: false},
    onDuplicate: {
      type: Function, default: () => {
      }
    }
  },
  data() {
    return {
      newStartDate: null,
      openDate: null,
      duplicated: false,
      newBooking: null,
      loading: false
    }
  },
  created() {
    const actualStartdate = this._parseDate(this.booking.startDate, "dd/MM/yyyy HH:mm:ss")
    if (this._isBefore(actualStartdate, new Date())) {
      this.openDate = this._formatDate(new Date(), "yyyy-MM-dd")

    } else {
      this.openDate = this._formatDate(actualStartdate, "yyyy-MM-dd")
      this.newStartDate = this._formatDate(actualStartdate, "yyyy-MM-dd")
    }

  },
  methods: {
    valid() {
      this.loading = true;
      get(`/api/booking/${this.booking.id}/duplicate/${this.newStartDate}`)
          .badRequest((err) => {
            this.loadingDetail = false;
            EventBus.$emit("toaster-msg", err);
          })
          .json((data) => {
            this.newBooking = data
            this.duplicated = true
            this.onDuplicate(data)
            EventBus.$emit("reloadbooking", {});
            this.loading = false;
          });
    },
    openDuplicate() {
      let route = this.$router.resolve({
        name: "Booking",
        params: {id: this.newBooking},
      });
      window.open(route.href, "_blank");
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
