<template>
  <layout>
    <template slot="nav-tools">
      <v-btn @click.native="createKagnot = true" color="primary">Créer une kagnot</v-btn>
    </template>
    <template slot="content">
      <v-container fluid class="pa-3">
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3">
            <v-tabs v-model="activeTabs">
              <v-tab>Coordonnées</v-tab>
              <v-tab>Kagnot</v-tab>
              <v-tab-item>
                <v-form ref="customerform" @submit.prevent="savecustomer">
                  <v-card>
                    <v-card-text class="py-0 px-1">
                      <v-row>
                        <v-col cols="4">
                          <v-subheader light class="pa-0 ma-0">Client</v-subheader>
                          <customer-infos v-model="customer.form.infos"></customer-infos>
                        </v-col>

                        <v-col cols="4">
                          <v-subheader light class="pa-0 ma-0">Adresse</v-subheader>
                          <customer-address v-model="customer.form.address"></customer-address>
                          <v-checkbox
                            v-model="customer.form.billingAddressIsSame"
                            dense
                            small
                            hide-details
                            label="Utiliser la même adresse pour la facturation"
                            class="pa-0 ma-0"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                          <v-subheader light class="pa-0 ma-0">Adresse de facturation</v-subheader>
                          <customer-address v-model="customer.form.billingAddress"></customer-address>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <smart-btn type="submit" primary :loading="customer.formloading">Enregistrer</smart-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <kagnot-list
                  :customerId="''+id"
                  :create="createKagnot"
                  @dialogClosed="createKagnot = false"
                ></kagnot-list>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row justify="space-between">
          <v-col cols="12" >
            <v-tabs v-model="tabsBookingLead">
              <v-tab>Dossiers</v-tab>
              <v-tab>Lead</v-tab>
              <v-tab-item>
                <bookings-list-table class="bookingListTable" ref="bookingListTable"></bookings-list-table>
              </v-tab-item>
              <v-tab-item>
                <leads-list-table class="mt-3" :leads-list="leadList" />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import CustomerAddress from "@/components/commons/CustomerAddress";
import CustomerInfos from "@/components/commons/CustomerInfos";
import KagnotList from "../../components/commons/KagnotList";
import { get, post } from "@/utils/api";
import BookingsListTable from "../../components/commons/BookingsListTable";
import { EventBus } from "@/components/commons/event-bus";
import LeadsListTable from "@/views/lead/leads-list-table.vue";

export default {
  name: "CustomerEdition",
  components: {
    LeadsListTable,
    BookingsListTable,
    Layout,
    CustomerInfos,
    CustomerAddress,
    KagnotList
  },
  props: ["id"],
  mixins: [],
  data() {
    return {
      customer: {
        form: {
          infos: {},
          billingAddressIsSame: false,
          address: {},
          billingAddress: {}
        },
        civilities: [
          { value: "Mister", text: "M." },
          { value: "Madam", text: "Mme" },
          { value: "Miss", text: "Mlle" }
        ],
        formloading: false
      },
      activeTabs: null,
      createKagnot: false,
      tabsBookingLead:null,
      leadList:[]
    };
  },
  created() {
    this.loadCutomer(this.id);
    this.loadLeadCustomer(this.id)
  },

  watch: {},
  computed: {},
  methods: {
    loadCutomer(id) {
      get(`/api/customer/${id}`)
        .json(d => {
          this.customer.form.infos = {
            id: d.id,
            civility: d.civility,
            email: d.email,
            firstname: d.firstname,
            lastname: d.lastname,
            fixe: d.fixe,
            otherPhones: d.otherPhones,
            selectedCountriesForNumber: d.selectedCountriesForNumber || "FR"
          };

          this.customer.form.address = d.address;
          this.customer.form.billingAddress = d.billingAddress;
          this.customer.form.billingAddressIsSame =
            this.customer.form.address.id ==
            this.customer.form.billingAddress.id;

          this.customer.formloading = false;
        })
        .then(() => {
          this.loadBookings();
        });
    },

    loadLeadCustomer(id){
      get(`/api/lead/customer/${id}`)
          .json((data) => {
            this.leadList = data
          });
    },

    savecustomer() {
      const valid = this.$refs.customerform.validate();

      if (valid) {
        this.customer.formloading = true;

        const form = {
          ...this.customer.form.infos,
          ...{ billingAddressIsSame: this.customer.form.billingAddressIsSame },
          ...{ address: this.customer.form.address },
          ...{
            billingAddress: this.customer.form.billingAddressIsSame
              ? null
              : this.customer.form.billingAddress
          }
        };

        const url = `/api/customer/update`;

        post(url, form)
          .badRequest(err => {
            EventBus.$emit("toaster-error", err);
          })
          .json(r => {
            this.loadCutomer(r.customer);
            this.loadBookings();
          })
          .then(() => {
            this.customer.formloading = false;
          });
      }
    },

    loadBookings() {
      const searchform = {
        selectedSites: [],
        selectedNetworks: [],
        selectedAgencies: [],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        supplierStatus: [],
        client: null,
        ref: null,
        bookingDateStart: null,
        bookingDateStop: null,
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: [
          "deleted",
          "cancelled",
          "quotation",
          "booked",
          "option"
        ],
        paymentStatus: null,
        customerId: this.id,
        tags: []
      };

      this.$refs.bookingListTable.load("/api/widget/bookings", searchform);
    }
  }
};
</script>

<style scoped>
</style>
